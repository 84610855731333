
import { defineComponent, ref, watch } from "vue";

import CfModal from "@/components/modal/Main.vue";
import CfButton from "@/components/button/Main.vue";
import CfInput from "@/components/input/Main.vue";

export default defineComponent({
  components: {
    CfModal,
    CfButton,
    CfInput
  },
  props: {
    data: Object,
  },
  emits: ["close", "submit"],
  setup(props, { emit }) {
    const current = ref<any>({});

    watch(() => props.data, value => Object.assign(current.value, value))

    const close = () => {
      emit('close')
    }

    const submit = async () => {
      emit('submit', current.value)
    }
    return {
      current,
      close,
      submit
    };
  },
});
