
import Cloudfun, { defineComponent, ref, reactive, watch, computed, onMounted } from "@cloudfun/core";
import { onUnmounted } from "vue";
import type { Project } from "@/models/data";
import { helper } from "@/utils/helper"

import CfGrid, { GridOptions, GridColumnOptions } from "@/components/grid/Main.vue";
import CfTabs from "@/components/tabs/Main.vue";
import CfSearchBar from "@/components/search-bar/Main.vue";
import CfToolBar from "@/components/tool-bar/Main.vue";
import RenameModal from "@/components/modal/rename/Main.vue";
import ConfirmRemoveModal from "@/components/modal/confirm-remove/Main.vue";
import SharingGroupModal from "@/components/modal/sharing-group/Main.vue";


export default defineComponent({
  components: {
    // CfTabsBar,
    CfGrid,
    CfToolBar,
    CfTabs,
    CfSearchBar,
    RenameModal,
    SharingGroupModal,
    ConfirmRemoveModal
  },
  setup() {
    const model = Cloudfun.current?.model;
    const router = Cloudfun.current?.policy?.router;
    const permissions: any = Cloudfun.current?.user.Permissions;

    const grid = ref<any>();
    const selectedRow = ref<Project | null>(null);
    const tabs: any = ref([])
    const isFiltered = ref(false);
    const gridOptions: GridOptions = {
      columns: [
        { field: "Name", title: "Project name", width: 300 },
        { field: "UpdatedTime", title: "Last modified", width: 150, sortable: true, formatter({ row }) { return helper.formatDate(row.UpdatedTime, "yyyy/MM/dd HH:mm") } },
        { field: "Model.Name", title: "Model name", width: 300 },
        { field: "Operator.Account", title: "Owner" },
      ],
      promises: {
        query: model
          ? (params) => new Promise((resolve) => {
            if(grid.value.keyword) params.keyword = grid.value.keyword
            params.sortings = params.sortings || [];
            model.dispatch('project/query', params).then(resp => {
              resolve(resp);
            });
          }) : undefined,
      }
    }

    const onGridCellClick = ({ row, column }: { row: Project, column: GridColumnOptions }) => {
      if (column.field === "Name") {
        router?.push("/project/" + row.Id);
        return;
      }
      selectedRow.value = row;
    }

    const onSearch = (value: string) => {
      // console.log(value);
      isFiltered.value = true;
      grid.value.keyword = value;
      grid.value.reload();
    }
    

    const newProjectModal = reactive({
      visible: false,
      show() {
        newProjectModal.visible = true;
      },
      async submit(item: Project) {
        console.log(item);
        try {
          const temp = await model?.dispatch("project/insert", item);
          newProjectModal.visible = false;
          // router?.push("/project/" + temp.Id);
        } catch (error: any) {
          Cloudfun.send("error", error);
        }
      }
    })

    const renameModal = reactive({
      visible: false,
      row: {} as Project,
      show() {
        renameModal.visible = true;
        renameModal.row = selectedRow.value!;
      },
      async submit(item: Project) {
        try {
          const temp = await model?.dispatch("project/update", item)
          selectedRow.value = temp;
          renameModal.visible = false;
          grid.value.reload();
        } catch (error: any) {
          Cloudfun.send("error", error);
        }
      }
    })

    const shareModal = reactive({
      visible: false,
      row: {} as Project,
      show() {
        shareModal.visible = true;
        shareModal.row = selectedRow.value!;
      }
    })

    const removeModal = reactive({
      visible: false,
      row: {} as Project,
      show() {
        removeModal.visible = true;
        removeModal.row = selectedRow.value!;
      },
      async submit(item: Project) {
        try {
          await model?.dispatch("project/delete", item.Id);
          selectedRow.value = null;
          removeModal.visible = false;
        } catch (error: any) {
          Cloudfun.send("error", error);
        }
      }
    })

    watch(() => model?.state["project"]["reloadUI"], value => {
      if (!value) return;
      grid.value.reload();
      model!.state["project"]["reloadUI"] = false;
    })

    let refreshDate = NaN;
    const handler = (event: any) => {
      const file = JSON.parse(event);
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).valueOf();
      if (!file.params && refreshDate !== today) {
        refreshDate = today;
        grid.value.reload();
      }
    }

    onMounted(()=>{
      Cloudfun.subscript('uploader:success', handler)
      tabs.value = [
        { name: "PROJECT", href: "/project", current: true, permission: permissions.includes('Project') },
        { name: "ALBUM", href: "/album", current: false, permission: permissions.includes('Album')},
        { name: "MODEL", href: "/model", current: false, permission: permissions.includes('Model')}
      ].filter((tab: any) => {
        return tab.permission === true;
      });
    })
    onUnmounted(() => Cloudfun.unsubscript('uploader:success', handler));

    return {
      grid,
      gridOptions,
      selectedRow,
      onGridCellClick,
      onSearch,
      isFiltered,
      tabs,
      newProjectModal,
      renameModal,
      shareModal,
      removeModal,
      toolbar: [
        {
          tooltipMsg: "Sharing group",
          icon: "icon-user",
        },
        {
          tooltipMsg: "Delete",
          icon: "icon-delete",
        },
        {
          tooltipMsg: "Rename",
          icon: "icon-edit",
        },
      ],
    };
  },
});
